import {
  GroupWork as GroupIcon,
  LockOpen as LockOpenIcon,
  PeopleOutline as PeopleOutlineIcon,
} from '@material-ui/icons';

const items = [
  {
    href: '/settings/access/managers',
    icon: PeopleOutlineIcon,
    module: 'managers',
    title: 'managers',
  },
  {
    href: '/settings/access/groups',
    icon: GroupIcon,
    module: 'groups',
    title: 'groups',
  },
  {
    href: '/settings/access/roles',
    icon: LockOpenIcon,
    module: 'roles',
    title: 'roles',
  },
  {
    href: '/settings/access/users',
    icon: PeopleOutlineIcon,
    module: 'users',
    title: 'users',
  },
];

export default items;
