import { lazy } from 'react';

import PublicFormsLayout from 'layouts/PublicFormsLayout';

const error404 = {
  component: lazy(() => import('views/Errors/Error404')),
};

const parentPath = '/public';

const publicRoutes = {
  path: parentPath,
  layout: PublicFormsLayout,
  routes: [
    {
      exact: true,
      path: `${parentPath}/betaleasing`,
      component: lazy(() => import('views/Public/Integrations/Betaleasing')),
    },
    {
      exact: true,
      path: `${parentPath}/people/form/:type`,
      component: lazy(() => import('views/Public/Persons/FormOld')),
    },
    {
      exact: true,
      path: `${parentPath}/people/form_new/:type`,
      component: lazy(() => import('views/Public/Persons/Form')),
    },
    {
      exact: true,
      path: `${parentPath}/people/sat/keys/:token`,
      component: lazy(() => import('views/Public/Persons/Sat/AccessKeys')),
    },
    {
      exact: true,
      path: `${parentPath}/integrations/enpagos`,
      component: lazy(() => import('views/Public/Integrations/Enpagos')),
    },
    error404,
  ],
};

export default publicRoutes;
