import { lazy } from 'react';

import AuthGuard from 'components/AuthGuard';
import DashboardLayout from 'layouts/DashboardLayout';

const error404 = {
  component: lazy(() => import('views/Errors/Error404')),
};

const dashboardRoutes = {
  path: '/',
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    {
      component: lazy(() => import('views/Assets/List')),
      exact: true,
      path: '/assets',
      permission: 'assets.select.listAssets',
    },
    {
      component: lazy(() => import('views/Assets/Edit')),
      exact: true,
      path: '/assets/add',
      permission: 'assets.insert.addAssets',
    },
    {
      component: lazy(() => import('views/Assets/Edit')),
      exact: true,
      path: '/assets/:id',
      permission: 'assets.update.editAssets',
    },
    {
      component: lazy(() => import('views/Assets/Groups')),
      exact: true,
      path: '/assets/groups',
    },
    {
      component: lazy(() => import('views/Banks/Inquiries/AccountBalance')),
      exact: true,
      path: '/banks/inquiries/account_balance',
    },
    {
      component: lazy(() => import('views/Banks/Inquiries/AccountStatus')),
      exact: true,
      path: '/banks/inquiries/account_status',
    },
    {
      component: lazy(() => import('views/Banks/Inquiries/Requests')),
      exact: true,
      path: '/banks/inquiries/requests',
    },
    {
      component: lazy(() => import('views/Banks/Normativity/Accounts/List')),
      exact: true,
      path: '/banks/normativity/accounts',
    },
    {
      component: lazy(() => import('views/Banks/Normativity/Accounts/Edit')),
      exact: true,
      path: '/banks/normativity/accounts/add',
    },
    {
      component: lazy(() => import('views/Banks/Normativity/Accounts/Edit')),
      exact: true,
      path: '/banks/normativity/accounts/:accountId',
    },
    {
      component: lazy(() => import('views/Banks/Normativity/AccountsTypes/List')),
      exact: true,
      path: '/banks/normativity/accountstypes',
    },
    {
      component: lazy(() => import('views/Banks/Normativity/AccountsTypes/Edit')),
      exact: true,
      path: '/banks/normativity/accountstypes/add',
    },
    {
      component: lazy(() => import('views/Banks/Normativity/AccountsTypes/Edit')),
      exact: true,
      path: '/banks/normativity/accountstypes/:accountTypeId',
    },
    {
      component: lazy(() => import('views/Banks/Normativity/Authorization/Levels/List')),
      exact: true,
      path: '/banks/normativity/authorization/levels',
    },
    {
      component: lazy(() => import('views/Banks/Normativity/Authorization/Levels/Edit')),
      exact: true,
      path: '/banks/normativity/authorization/levels/add',
    },
    {
      component: lazy(() => import('views/Banks/Normativity/Authorization/Levels/Edit')),
      exact: true,
      path: '/banks/normativity/authorization/levels/:levelId',
    },
    {
      component: lazy(() => import('views/Banks/Normativity/Checkbooks/List')),
      exact: true,
      path: '/banks/normativity/checkbooks',
    },
    {
      component: lazy(() => import('views/Banks/Normativity/Providers/List')),
      exact: true,
      path: '/banks/normativity/providers',
    },
    {
      component: lazy(() => import('views/Banks/Normativity/Providers/Edit')),
      exact: true,
      path: '/banks/normativity/providers/add',
    },
    {
      component: lazy(() => import('views/Banks/Normativity/Providers/Edit')),
      exact: true,
      path: '/banks/normativity/providers/:providerId',
    },
    {
      component: lazy(() => import('views/Banks/Normativity/Taxes/List')),
      exact: true,
      path: '/banks/normativity/taxes',
    },
    {
      component: lazy(() => import('views/Banks/Normativity/Taxes/Edit')),
      exact: true,
      path: '/banks/normativity/taxes/add',
    },
    {
      component: lazy(() => import('views/Banks/Normativity/Taxes/Edit')),
      exact: true,
      path: '/banks/normativity/taxes/:taxId',
    },
    {
      component: lazy(() => import('views/Banks/Normativity/Transactions/List')),
      exact: true,
      path: '/banks/normativity/transactions',
    },
    {
      component: lazy(() => import('views/Banks/Operations/List')),
      exact: true,
      path: '/banks/operations',
    },
    {
      component: lazy(() => import('views/Banks/Operations/Edit')),
      exact: true,
      path: '/banks/operations/add',
    },
    {
      component: lazy(() => import('views/Banks/Operations/Edit')),
      exact: true,
      path: '/banks/operations/:operationId',
    },
    {
      component: lazy(() => import('views/Banks/Operations/BulkLoad')),
      exact: true,
      path: '/banks/operations/bulk_load',
    },
    {
      component: lazy(() => import('views/Banks/Operations/Cancelations')),
      exact: true,
      path: '/banks/operations/cancelations',
    },
    {
      component: lazy(() => import('views/Banks/Operations/Conciliation')),
      exact: true,
      path: '/banks/operations/conciliation',
    },
    {
      component: lazy(() => import('views/Banks/Operations/Deposits')),
      exact: true,
      path: '/banks/operations/deposits',
    },
    {
      component: lazy(() => import('views/Banks/Operations/Requests/Add')),
      exact: true,
      path: '/banks/operations/requests/add',
    },
    {
      component: lazy(() => import('views/Banks/Operations/Requests/Authorize')),
      exact: true,
      path: '/banks/operations/requests/authorize',
    },
    {
      component: lazy(() => import('views/Banks/Operations/Reversal')),
      exact: true,
      path: '/banks/operations/reversal',
    },
    {
      component: lazy(() => import('views/Banks/Operations/Transfers')),
      exact: true,
      path: '/banks/operations/transfers',
    },
    {
      component: lazy(() => import('views/Banks/Operations/Withdrawals')),
      exact: true,
      path: '/banks/operations/withdrawals',
    },
    {
      component: lazy(() => import('views/Billing/List')),
      exact: true,
      path: '/billing',
    },
    {
      component: lazy(() => import('views/Condusef/Redeco/Complaints/List')),
      exact: true,
      path: '/condusef/redeco',
    },
    {
      component: lazy(() => import('views/Condusef/Redeco/Complaints/Edit')),
      exact: true,
      path: '/condusef/redeco/add',
    },
    {
      component: lazy(() => import('views/Condusef/Redeco/Complaints/Edit')),
      exact: true,
      path: '/condusef/redeco/:id',
    },
    {
      component: lazy(() => import('views/Condusef/List')),
      exact: true,
      path: '/condusef/reune',
    },
    {
      component: lazy(() => import('views/Condusef/Edit')),
      exact: true,
      path: '/condusef/reune/add',
    },
    {
      component: lazy(() => import('views/Condusef/Edit')),
      exact: true,
      path: '/condusef/reune/:id',
    },
    {
      component: lazy(() => import('views/Dashboard')),
      exact: true,
      path: '/dashboard',
      permission: 'dashboard.select.listDashboard',
    },
    {
      component: lazy(() => import('views/Devices/List')),
      exact: true,
      path: '/devices',
      permission: 'devices.select.listDevices',
    },
    {
      component: lazy(() => import('views/Devices/Details')),
      exact: true,
      path: '/devices/:imei',
    },
    {
      component: lazy(() => import('views/Logs/Binnacle/List')),
      exact: true,
      path: '/logs/binnacle',
      permission: 'logs.select.listLogs',
    },
    {
      component: lazy(() => import('views/Logs/Binnacle/List')),
      exact: true,
      path: '/logs/binnacle/:table/:id',
      permission: 'logs.select.listLogs',
    },
    {
      component: lazy(() => import('views/Notifications/List')),
      exact: true,
      path: '/notifications',
    },
    {
      component: lazy(() => import('views/Notifications/Details')),
      exact: true,
      path: '/notifications/:id',
    },
    {
      component: lazy(() => import('views/Payments/List')),
      exact: true,
      path: '/payments',
      permission: 'payments.select.listPayments',
    },
    {
      component: lazy(() => import('views/PaymentOrders/List')),
      exact: true,
      path: '/payment_orders',
      permission: 'paymentOrders.select.listPaymentOrders',
    },
    {
      component: lazy(() => import('views/DepositDetails/List')),
      exact: true,
      path: '/deposit_details',
      // permission: 'deposits.select.listDepositDetails',
    },
    {
      component: lazy(() => import('views/Deposits/List')),
      exact: true,
      path: '/deposits',
      // permission: 'deposits.select.listDeposits',
    },
    // FIXME: Delete this
    {
      component: lazy(() => import('views/PaymentOrders/List')),
      exact: true,
      path: '/payments_order',
      permission: 'paymentOrders.select.listPaymentOrders',
    },
    {
      component: lazy(() => import('views/Persons/List')),
      exact: true,
      path: '/people',
    },
    {
      component: lazy(() => import('views/Persons/Accounts')),
      exact: true,
      path: '/people/accounts',
    },
    {
      component: lazy(() => import('views/Persons/Add')),
      exact: true,
      path: '/people/add',
      permission: 'clients.insert.addClients',
    },
    {
      component: lazy(() => import('views/Persons/Balance')),
      exact: true,
      path: '/people/balance',
    },
    {
      component: lazy(() => import('views/Persons/Stages')),
      exact: true,
      path: '/people/stages',
    },
    {
      component: lazy(() => import('views/Persons/Stages.V2')),
      exact: true,
      path: '/people/stages.v2',
    },
    {
      component: lazy(() => import('views/Persons/Edit')),
      exact: true,
      path: '/people/:peopleId',
    },
    {
      component: lazy(() => import('views/Persons/Pld')),
      exact: true,
      path: '/people/:peopleId/pld',
    },
    {
      component: lazy(() => import('views/Persons/PldModule')),
      exact: true,
      path: '/people/:peopleId/pld/:queryId',
    },
    {
      component: lazy(() => import('views/Persons/Products/Movements/List')),
      exact: true,
      path: '/people/:peopleId/products/movements',
    },
    {
      component: lazy(() => import('views/Persons/Products/Movements/Add')),
      exact: true,
      path: '/people/:peopleId/products/movements/add',
    },
    {
      component: lazy(() => import('views/Persons/Products')),
      exact: true,
      path: '/people/:peopleId/products/:productId',
    },
    {
      component: lazy(() => import('views/Persons/Redirect')),
      exact: true,
      path: '/people/:peopleId/:tabName',
    },
    ///////////////////////////////////////////////////////////////////////////
    {
      component: lazy(() => import('views/Persons/Edit.V2')),
      exact: true,
      path: '/people.v2/:peopleId',
    },
    {
      component: lazy(() => import('views/Persons/Products.V2')),
      exact: true,
      path: '/people.v2/:peopleId/products/:productId',
    },
    ///////////////////////////////////////////////////////////////////////////
    {
      component: lazy(() => import('views/Aml/Alerts/List')),
      exact: true,
      path: '/pld/alerts',
      permission: 'pld.select',
    },
    {
      component: lazy(() => import('views/Aml/Reports/Calendar')),
      exact: true,
      path: '/pld/reports/calendar',
      permission: 'pld.select',
    },
    {
      component: lazy(() => import('views/Aml/Reports/Internal/List')),
      exact: true,
      path: '/pld/reports/internal',
      permission: 'pld.select',
    },
    {
      component: lazy(() => import('views/Aml/Reports/Internal/Edit')),
      exact: true,
      path: '/pld/reports/internal/add',
      permission: 'pld.select',
    },
    {
      component: lazy(() => import('views/Aml/Reports/Internal/Edit')),
      exact: true,
      path: '/pld/reports/internal/:reportId',
      permission: 'pld.select',
    },
    {
      component: lazy(() => import('views/Aml/Reports/ResearchCases')),
      exact: true,
      path: '/pld/reports/investigation_cases',
      permission: 'pld.select',
    },
    {
      component: lazy(() => import('views/Aml/Analysis/ClientAccounts')),
      exact: true,
      path: '/pld/analysis/client_movements',
      permission: 'pld.select',
    },
    {
      component: lazy(() => import('views/Aml/Matrix/List')),
      exact: true,
      path: '/pld/analysis/risk_matrix',
      permission: 'pld.select',
    },
    {
      component: lazy(() => import('views/Aml/Matrix/Edit')),
      exact: true,
      path: '/pld/analysis/risk_matrix/add',
      permission: 'pld.select',
    },
    {
      component: lazy(() => import('views/Aml/Matrix/Edit')),
      exact: true,
      path: '/pld/analysis/risk_matrix/:matrixId',
      permission: 'pld.select',
    },
    {
      component: lazy(() => import('views/Aml/Matrix/Historical/List')),
      exact: true,
      path: '/pld/analysis/risk_matrix/:matrixId/historical',
    },
    {
      component: lazy(() => import('views/Aml/Matrix/Historical/Details')),
      exact: true,
      path: '/pld/analysis/risk_matrix/:matrixId/historical/:historicalId',
    },
    {
      component: lazy(() => import('views/Aml/Alerts/GroupingOfAccountsByCustomers')),
      exact: true,
      path: '/pld/customer_accounts',
      permission: 'pld.select',
    },
    {
      component: lazy(() => import('views/Aml/Alerts/GroupingOfAccountsByCustomers/DetailsOfMovementsByAccount')),
      exact: true,
      path: '/pld/customer_accounts/:groupingId',
      permission: 'pld.select',
    },
    {
      component: lazy(() => import('views/Aml/Alerts/GroupingOfAccountsByCustomers/AdditionalInformation')),
      exact: true,
      path: '/pld/customer_accounts/:groupingId/:detailId',
      permission: 'pld.select',
    },
    {
      component: lazy(() => import('views/Aml/Catalogs/AlertTypes/List')),
      exact: true,
      path: '/pld/catalogs/alert_types',
    },
    {
      component: lazy(() => import('views/Aml/Catalogs/AlertTypes/Edit')),
      exact: true,
      path: '/pld/catalogs/alert_types/add',
    },
    {
      component: lazy(() => import('views/Aml/Catalogs/AlertTypes/Edit')),
      exact: true,
      path: '/pld/catalogs/alert_types/:id',
    },
    {
      component: lazy(() => import('views/Aml/Catalogs/Instruments')),
      exact: true,
      path: '/pld/catalogs/monetary_instruments',
      permission: 'pld.select',
    },
    {
      component: lazy(() => import('views/Aml/Catalogs/OppEliminar')),
      exact: true,
      path: '/pld/catalogs/opp',
    },
    {
      component: lazy(() => import('views/Aml/Catalogs/OperationTypes')),
      exact: true,
      path: '/pld/catalogs/operation_types',
    },
    {
      component: lazy(() => import('views/Aml/Catalogs/OperationMaster')),
      exact: true,
      path: '/pld/catalogs/operation_master',
    },
    {
      component: lazy(() => import('views/Aml/Catalogs/GeneralParams')),
      exact: true,
      path: '/pld/catalogs/parameters',
      permission: 'pld.select',
    },
    {
      component: lazy(() => import('views/Aml/Catalogs/Accions/List')),
      exact: true,
      path: '/pld/catalogs/actions',
      permission: 'pld.select',
    },
    {
      component: lazy(() => import('views/Aml/Catalogs/ReportingReasons')),
      exact: true,
      path: '/pld/catalogs/reporting_reasons',
      permission: 'pld.select',
    },
    {
      component: lazy(() => import('views/Aml/Catalogs/Jurisdictions')),
      exact: true,
      path: '/pld/catalogs/country_jurisdiction',
      permission: 'pld.select',
    },
    {
      component: lazy(() => import('views/Aml/Analysis/BlackList/List')),
      exact: true,
      path: '/pld/analysis/black_list',
      permission: 'pld.select',
    },
    {
      component: lazy(() => import('views/Aml/Analysis/BlackList/List/OpenSearchTab/Edit')),
      exact: true,
      path: '/pld/analysis/black_list/add',
    },
    {
      component: lazy(() => import('views/Aml/Analysis/BlackList/List/OpenSearchTab/Edit')),
      exact: true,
      path: '/pld/analysis/black_list/:id',
    },
    {
      component: lazy(() => import('views/Aml/OpenSearch/List')),
      exact: true,
      path: '/pld/catalogs/black_list',
      permission: 'pld.select',
    },
    {
      component: lazy(() => import('views/Aml/OpenSearch/Edit')),
      exact: true,
      path: '/pld/catalogs/black_list/add',
    },
    {
      component: lazy(() => import('views/Aml/OpenSearch/Edit')),
      exact: true,
      path: '/pld/catalogs/black_list/:id',
    },
    {
      component: lazy(() => import('views/Reports/List')),
      exact: true,
      path: '/reports',
      permission: 'reports.select.listReports',
    },
    {
      component: lazy(() => import('views/Reports/Edit')),
      exact: true,
      path: '/reports/add',
    },
    {
      component: lazy(() => import('views/Reports/Edit')),
      exact: true,
      path: '/reports/:reportId',
    },
    {
      component: lazy(() => import('views/Reports/System')),
      exact: true,
      path: '/reports/system/:name',
    },
    {
      component: lazy(() => import('views/Settings/Access/Groups')),
      exact: true,
      path: '/settings/access/groups',
      permission: 'groups.select.listGroups',
    },
    {
      component: lazy(() => import('views/Settings/Access/Managers/List')),
      exact: true,
      path: '/settings/access/managers',
    },
    {
      component: lazy(() => import('views/Settings/Access/Roles/List')),
      exact: true,
      path: '/settings/access/roles',
      permission: 'roles.select.listRoles',
    },
    {
      component: lazy(() => import('views/Settings/Access/Roles/Edit')),
      exact: true,
      path: '/settings/access/roles/add',
      permission: 'roles.insert.addRoles',
    },
    {
      component: lazy(() => import('views/Settings/Access/Roles/Edit')),
      exact: true,
      path: '/settings/access/roles/:rolId',
      permission: 'roles.update.updateRoles',
    },
    {
      component: lazy(() => import('views/Settings/Access/Users/List')),
      exact: true,
      path: '/settings/access/users',
      permission: 'users.select.listUsers',
    },
    {
      component: lazy(() => import('views/Settings/Access/Users/Details')),
      exact: true,
      path: '/settings/access/users/:userId',
    },
    {
      component: lazy(() => import('views/Settings/Assistant')),
      exact: true,
      path: '/settings/assistant',
      permission: 'assistant.select.listAssistant',
    },
    {
      component: lazy(() => import('views/Settings/Assistant.new')),
      exact: true,
      path: '/settings/assistant_new',
      permission: 'assistant.select.listAssistant',
    },
    {
      component: lazy(() => import('views/Settings/Calendar')),
      exact: true,
      path: '/settings/calendar',
    },
    {
      component: lazy(() => import('views/Settings/Campaigns/List')),
      exact: true,
      path: '/settings/campaigns',
      permission: 'campaigns.select.listCampaigns',
    },
    {
      component: lazy(() => import('views/Settings/Campaigns/Edit')),
      exact: true,
      path: '/settings/campaigns/add',
      // permission: 'campaigns.insert.addCampaigns',
    },
    {
      component: lazy(() => import('views/Settings/Campaigns/Edit')),
      exact: true,
      path: '/settings/campaigns/:campaignId',
      // permission: 'campaigns.update.updateCampaigns',
    },
    {
      component: lazy(() => import('views/Settings/Campaigns/Historical')),
      exact: true,
      path: '/settings/campaigns/:campaignId/historical',
    },
    {
      component: lazy(() => import('views/Settings/Campaigns/Historical/Details')),
      exact: true,
      path: '/settings/campaigns/:campaignId/historical/:historicalId',
    },
    {
      component: lazy(() => import('views/Settings/Marketplace/List')),
      exact: true,
      path: '/settings/marketplace',
      // permission: 'marketplace.select.listMarketplace',
    },
    {
      component: lazy(() => import('views/Settings/Marketplace/Details')),
      exact: true,
      path: '/settings/marketplace/:id',
      // permission: 'marketplace.update.editMarketplace',
    },
    {
      component: lazy(() => import('views/Settings/Company')),
      exact: true,
      path: '/settings/company',
      permission: 'company.select.getCompany',
    },
    {
      component: lazy(() => import('views/Settings/Flows/List')),
      exact: true,
      path: '/settings/flows',
      permission: 'flows.select.listFlows',
    },
    {
      component: lazy(() => import('views/Settings/Flows/Edit')),
      exact: true,
      path: '/settings/flows/add',
      permission: 'flows.insert.addFlows',
    },
    {
      component: lazy(() => import('views/Settings/Flows/Edit')),
      exact: true,
      path: '/settings/flows/:flowId',
      permission: 'flows.update.updateFlows',
    },
    {
      component: lazy(() => import('views/Settings/Parameters/List')),
      exact: true,
      path: '/settings/parameters',
      permission: 'parameters.select.listParameters',
    },
    {
      component: lazy(() => import('views/Settings/Templates/Forms/List')),
      exact: true,
      path: '/settings/templates/forms',
      permission: 'clientTypes.select.listClientTypes',
    },
    {
      component: lazy(() => import('views/Settings/Templates/Forms/Edit')),
      exact: true,
      path: '/settings/templates/forms/add',
    },
    {
      component: lazy(() => import('views/Settings/Templates/Forms/Edit')),
      exact: true,
      path: '/settings/templates/forms/:formId',
    },
    {
      component: lazy(() => import('views/Settings/Templates/People/List')),
      exact: true,
      path: '/settings/templates/people',
      permission: 'clientTypes.select.listClientTypes',
    },
    {
      component: lazy(() => import('views/Settings/Templates/People/Edit')),
      exact: true,
      path: '/settings/templates/people/add',
    },
    {
      component: lazy(() => import('views/Settings/Templates/People/Relationships/List')),
      exact: true,
      path: '/settings/templates/people/relationships',
    },
    {
      component: lazy(() => import('views/Settings/Templates/People/Stages/List')),
      exact: true,
      path: '/settings/templates/people/stages',
    },
    {
      component: lazy(() => import('views/Settings/Templates/People/Stages.V2/List')),
      exact: true,
      path: '/settings/templates/people/stages.v2',
    },
    {
      component: lazy(() => import('views/Settings/Templates/People/Stages/Edit')),
      exact: true,
      path: '/settings/templates/people/stages/add',
    },
    {
      component: lazy(() => import('views/Settings/Templates/People/Stages.V2/Edit')),
      exact: true,
      path: '/settings/templates/people/stages.v2/add',
    },
    {
      component: lazy(() => import('views/Settings/Templates/People/Stages/Edit')),
      exact: true,
      path: '/settings/templates/people/stages/:id',
    },
    {
      component: lazy(() => import('views/Settings/Templates/People/Stages.V2/Edit')),
      exact: true,
      path: '/settings/templates/people/stages.v2/:id',
    },
    {
      component: lazy(() => import('views/Settings/Templates/People/Edit')),
      exact: true,
      path: '/settings/templates/people/:peopleTypeId',
    },
    {
      component: lazy(() => import('views/Settings/Templates/People/Evaluations/List')),
      exact: true,
      path: '/settings/templates/people/:peopleTypeId/evaluations',
    },
    {
      component: lazy(() => import('views/Settings/Templates/Products/List')),
      exact: true,
      path: '/settings/templates/products',
      permission: 'productTypes.select.listProductTypes',
    },
    {
      component: lazy(() => import('views/Settings/Templates/Products/Edit')),
      exact: true,
      path: '/settings/templates/products/add',
    },
    {
      component: lazy(() => import('views/Settings/Templates/Products/Edit')),
      exact: true,
      path: '/settings/templates/products/:productId',
    },
    {
      component: lazy(() => import('views/Settings/Templates/Products/Simulator')),
      exact: true,
      path: '/settings/templates/products/:productId/simulator',
    },
    {
      component: lazy(() => import('views/Settings/Templates/Notifications/List')),
      exact: true,
      path: '/settings/templates/notifications',
      permission: 'notificationTypes.select.listNotificationTypes',
    },
    {
      component: lazy(() => import('views/Settings/Templates/Notifications/Edit')),
      exact: true,
      path: '/settings/templates/notifications/add',
    },
    {
      component: lazy(() => import('views/Settings/Templates/Notifications/Edit')),
      exact: true,
      path: '/settings/templates/notifications/:templateId',
    },
    {
      component: lazy(() => import('views/Settings/Templates/Notifications/Edit.tiny')),
      exact: true,
      path: '/settings/templates/notifications.v2/add',
    },
    {
      component: lazy(() => import('views/Settings/Templates/Notifications/Edit.tiny')),
      exact: true,
      path: '/settings/templates/notifications.v2/:id',
    },
    {
      component: lazy(() => import('views/Cards/List')),
      exact: true,
      path: '/cards',
    },
    {
      component: lazy(() => import('views/Cards/Details')),
      exact: true,
      path: '/cards/:id',
    },
    {
      component: lazy(() => import('views/Transactions/List')),
      exact: true,
      path: '/movements/transactions',
    },
    {
      component: lazy(() => import('views/Facturation/List')),
      exact: true,
      path: '/finance/billing',
      permission: 'billing.select.listBilling',
    },
    {
      component: lazy(() => import('views/Commerce/List')),
      exact: true,
      path: '/commerce',
      permission: 'commerce.select.listCommerce',
    },
    {
      component: lazy(() => import('views/Commerce/Add')),
      exact: true,
      path: '/commerce/add',
      permission: 'commerce.select.addCommerce',
    },
    {
      component: lazy(() => import('views/Developers/List')),
      exact: true,
      path: '/developers',
      permission: 'developers.select.listDevelopers',
    },
    {
      component: lazy(() => import('views/PaymentLinks/List')),
      exact: true,
      path: '/payment_links',
      permission: 'payments.select.listLinks',
    },
    {
      component: lazy(() => import('views/PaymentLinks/Edit')),
      exact: true,
      path: '/payment_links/add',
      permission: 'payments.select.listLinks',
    },
    error404,
  ],
};

export default dashboardRoutes;
