import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Switch,
} from '@material-ui/core';

import useStyles from './styles';

function SwitchControl(props) {
  const classes = useStyles();
  const {
    events = {},
    error,
    helperText,
    label,
    name,
    onChange,
    size,
    type,
    value,
  } = props;

  const Control = type === 'checkbox'
    ? Checkbox
    : Switch;

  const handleChange = ({ target: { checked } }) => {
    const target = {
      name,
      value: checked,
    };
    onChange({
      target,
    });

    if (events.onChange) {
      events.onChange(target);
    }
  };

  return (<FormControl error={error}>
    <FormGroup>
      <FormControlLabel
        className={error ? classes.error : undefined}
        control={<Control
          checked={value}
          color="primary"
          name={name}
          size={size}
          onChange={handleChange}
        />}
        helperText={helperText}
        label={label}
      />
      <FormHelperText children={helperText} />
    </FormGroup>
  </FormControl>);
}

SwitchControl.defaultProps = {
  size: 'small',
  type: 'switch',
  value: false,
  onChange: () => { },
};

SwitchControl.propTypes = {
  events: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf([
    'small',
    'medium',
  ]),
  type: PropTypes.oneOf([
    'checkbox',
    'switch',
  ]),
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

export default SwitchControl;
